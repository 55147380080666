














import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { Vue, Component, Prop } from "vue-property-decorator";
import { MobileMainProperties } from "./MobileMainView.vue";

const MobilePendingOrderBView = () => import("./MobilePendingOrderBView.vue");
const MobilePendingSummaryView = () => import("./MobilePendingSummaryView.vue");

class MobilePendingOrderProperties {
    pendingOrders: any[] = [];
    currency: any = null;
}

export { MobilePendingOrderProperties }

@Component({
    components: {
        MobilePendingOrderBView,
        MobilePendingSummaryView
    }
})
export default class MobilePendingOrderAView extends Vue {
    @Prop() private main: MobileMainProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    private properties = new MobilePendingOrderProperties();
}
